<template>
  <div class="userInfoContent">
    <!-- content -->
    <h3>*&nbsp;请绑定本人的银行卡</h3>
    <ul>
      <li>
        <label>持卡人</label>
        <p>{{ detail == "" ? "" : detail.name }}</p>
      </li>
      <li>
        <label>所属行</label>
        <p>{{ detail == "" ? "" : detail.bank_name }}</p>
      </li>
      <li>
        <label>卡号</label>
        <p v-show="!changeIcon">{{ detail == "" ? "" : detail.bank_card }}</p>
        <mu-text-field
          v-show="changeIcon"
          v-model="bankNumber"
          :action-icon="bankNumber == '' ? '' : 'cancel'"
          :action-click="() => (bankNumber = '')"
        ></mu-text-field>
      </li>
    </ul>
    <h4 v-show="!changeIcon">
      <mu-button flat color="#ff805c" @click="changeIcon = true"
        >更换银行卡</mu-button
      >
    </h4>
    <div class="submitStyle" v-show="changeIcon">
      <mu-button
        @click="changeIcon = false"
        style="min-width: 60px; margin-right: 20px"
        >取消</mu-button
      >
      <mu-button color="#f15a60" style="min-width: 60px" @click="saveBankNumber"
        >保存</mu-button
      >
    </div>
    <!-- content end -->
    <!-- 认证成功 -->
    <aside class="dialogStyle" v-show="showIsSuccessPage">
      <div>
        <h3>恭喜您，修改成功了！</h3>
        <img src="../assets/success.png" alt="" />
        <div>
          <mu-button
            style="margin-left: 0"
            color="#00b935"
            @click="onBackWechat"
            >确定</mu-button
          >
        </div>
      </div>
    </aside>
    <!-- 认证成功 end -->
  </div>
</template>
<script>
import axios from "axios";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      changeIcon: false,
      bankNumber: "",
      detail: "",
      showIsSuccessPage: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      let url =
        this.httpsBasic.url + this.httpsBasic.user + "St_user/getBankInfo";
      let params = {
        token: this.$route.query.token,
        company_secret: "d6cce43f111c82d43ea4f5b7d42ae91669ee14e9",
      };
      axios.post(url, params).then((res) => {
        if (res.data.code == 200) {
          this.detail = res.data.data;
          this.bankNumber = this.detail.bank_card;
          // console.log(this.detail);
        } else if (res.data.code == -11 || res.data.code == -10) {
          this.loading = false;
          this.$confirm(" 登录失效，将跳转至授权页面！", "提示").then(
            ({ result }) => {
              if (result) {
                 wx.miniProgram.navigateBack();
              }
              //
            }
          );
        } else {
          this.$toast.message(res.data.message);
        }
      });
    },
    verify() {
      let fls = true;
      if (this.bankNumber == this.detail.bank_card) {
        this.$toast.message("银行卡号未修改");
        fls = false;
        return fls;
      }
      if (this.bankNumber == "") {
        this.$toast.message("请输入银行卡号");
        fls = false;
        return fls;
      }
      // console.log(this.bankNumber.length);
      if (this.bankNumber.length < 16) {
        this.$toast.message("请输入正确的银行卡号");
        fls = false;
        return fls;
      }
      if (this.bankNumber.length > 19) {
        this.$toast.message("请输入正确的银行卡号");
        fls = false;
        return fls;
      }
      return fls;
    },
    onBackWechat() {
      wx.miniProgram.navigateBack();
    },
    saveBankNumber() {
      let fls = this.verify();
      if (fls) {
        let url =
          this.httpsBasic.url + this.httpsBasic.user + "St_user/updateBankInfo";
        let params = {
          token: this.$route.query.token,
          company_secret: "d6cce43f111c82d43ea4f5b7d42ae91669ee14e9",
          bank_card_number: this.bankNumber,
        };
        // console.log(params);
        axios.post(url, params).then((res) => {
          if (res.data.code == 200) {
            this.showIsSuccessPage = true;
            this.getInfo();
          } else {
            this.$alert(res.data.message, "提示", {
              okLabel: "知道了",
            }).then(() => {});
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userInfoContent {
  padding-top: 108px;
  background: #fff;
  min-height: 100vh;
  > h3 {
    background: rgba(255, 128, 92, 0.1);
    color: #999;
    padding: 30px 3.2%;
    font-size: 28px;
    line-height: 52px;
  }
  ul {
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 3.2% 0;
      border-bottom: 1px solid #ccc;
      label,
      p {
        font-size: 30px;
        line-height: 52px;
        color: #666;
      }
    }
    p {
      padding: 24px 0;
    }
    > li:first-child,
    > li:nth-child(2) {
      padding: 44px 3.2% 24px;
      p {
        padding: 0;
      }
    }
  }
  h4 {
    font-size: 28px;
    text-align: right;
    margin: 50px 0;
  }
  .submitStyle {
    text-align: center;
    margin: 120px 0;
  }
  .dialogStyle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    > div {
      background: #fff;
      position: relative;
      padding: 30px 30px 50px;
      margin: 50% 6%;
      border-radius: 20px;
      min-height: 500px;
      h3 {
        font-size: 36px;
        margin: 50px 0 30px;
        text-align: center;
      }
      p {
        font-size: 28px;
        line-height: 52px;
        margin: 20px 4%;
        min-height: 200px;
        text-align: center;
      }
      > div {
        text-align: center;
      }
      button {
        margin-left: 40px;
        margin-top: 40px;
      }
      img {
        height: 140px;
        display: block;
        margin: 50px auto 60px;
      }
    }
  }
}
</style>
<style lang="less">
.userInfoContent {
  .mu-input {
    margin: 0;
    padding: 0;
    min-height: unset;
    width: 70%;
  }
  .mu-input-line {
    height: unset;
    display: none;
  }
  .mu-text-field-input {
    padding: 24px 0;
    text-align: right;
    letter-spacing: 1px;
  }
  .mu-input__focus {
    color: #f15a60;
  }
}
</style>
